import React, {useState} from 'react'
import {navigate} from 'gatsby'
import {Alert, Button, Container, Form, Modal} from 'react-bootstrap'
import Layout from "../components/layout"
import queryString from 'query-string'
import axios from 'axios'

export default ({location}) => {

    const [state, setState] = useState({
        dirty: false,
        validated: false,
        error_msg: '',
        info_msg: '',
        gift_id: queryString.parse(location.search).gift_id,
        code: '',
        email: '',
        email_verify: '',
    });

    function handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            dirty: true,
            [name]: value
        })
    }

    function handleSubmit(event) {
        setState({
            ...state,
            dirty: false,
            error_msg: ''
        })
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setState({...state, validated: true})
            return
        }
        if (state.email !== state.email_verify) {
            setState({...state, validated: true, error_msg: 'Les emails ne correspondent pas'})
            return
        }

        setState({...state, validated: true, dirty: false, error_msg: ''});

        axios({method: 'post', url: '/.netlify/functions/sendGift', data: state}).then(response => {
            setState({...state, info_msg: 'Votre carte cadeau a bien été envoyée à ' + state.email})
        }).catch((err) => {
            console.log(err.response)
            if (err.response) {
                if (err.response.status === 500) {
                    alert("Une erreur technique est survenue, veuillez contacter notre équipe afin qu'on puisse résoudre le problème dans les meilleurs délais")
                } else if (err.response.status === 400) {
                    setState({...state, error_msg: err.response.data.msg})
                }
            }
        })
    }

    return (
        <Layout>
            <Container className="text-center">
                <Modal show={true} onHide={() => navigate('/')} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Envoyer votre carte cadeau</Modal.Title>
                    </Modal.Header>
                    <Form
                        validated={state.validated}
                        onSubmit={handleSubmit}
                        className="border rounded px-4 needs-validation" noValidate>

                        <Form.Label className="my-2 mx-2">
                            Email de la personne à qui vous souhaitez offrir la carte cadeau
                        </Form.Label>
                        <Form.Control
                            name="email"
                            onChange={handleChange}
                            value={state.email}
                            type="email"
                            pattern=".+@.+\..+"
                            required
                        />
                        <Form.Label className="my-2 mx-2">
                            Validez l'email de la personne à qui vous souhaitez offrir la carte cadeau
                        </Form.Label>
                        <Form.Control
                            name="email_verify"
                            onChange={handleChange}
                            value={state.email_verify}
                            type="email"
                            pattern=".+@.+\..+"
                            required
                        />
                        <Form.Label className="my-2 mx-2">
                            Code de confirmation que vous avez reçu dans l'email de votre commande de bon d'achat
                        </Form.Label>
                        <Form.Control
                            name="code"
                            onChange={handleChange}
                            value={state.code}
                            required
                        />

                        <Button className={"btn btn-green my-4"} type="submit" disabled={! state.dirty}>
                            Offrir la carte cadeau
                        </Button>
                    </Form>
                    <Alert variant="danger" className={state.error_msg ? '' : 'd-none'}>{state.error_msg}</Alert>
                    <Alert variant="green" className={state.info_msg ? '' : 'd-none'}>{state.info_msg}</Alert>

                </Modal>
            </Container>
        </Layout>
    )
}